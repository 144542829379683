import React from 'react'

import StyledLayout from './styles'

const Layout = React.forwardRef((props, ref) => (
  <StyledLayout ref={ref} {...props} />
))

Layout.displayName = 'Layout'

export default Layout
