import React from 'react'
import PropTypes from 'prop-types'

import StyledButton from './styles'

const Button = ({ renderAs, children, primary, noTarget, ...rest }) =>
  <StyledButton
    as={renderAs}
    primary={primary && 'true'}
    rel={noTarget && 'noopener noreferrer'}
    target={noTarget && '_blank'}
    type={renderAs === 'button' ? 'button' : undefined}
    {...rest}
  >
    {children}
  </StyledButton>

Button.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  primary: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  noTarget: PropTypes.bool,
  children: PropTypes.node.isRequired
}

Button.defaultProps = {
  renderAs: 'button'
}

export default Button
