import styled from '@emotion/styled'

export default styled.a`
  color: #0c4887;
  text-decoration: underline;
  cursor: pointer;

  :hover
  {
    text-decoration: none;
  }
`
