import React from 'react'
import { Link } from 'gatsby'
import Layout from '../Layout'

import {
  FooterContainer,
  FooterLinks,
  FooterLink,
  CopyrightNote
} from './styles'

const Footer = () => {
  return (
    <Layout as='footer'>
      <FooterContainer>
        <FooterLinks>
          <FooterLink
            rel='noopener noreferrer'
            target='_blank'
            href='https://www.linkedin.com/in/annearndt/'
          >
            Follow me on LinkedIn
          </FooterLink>
          <FooterLink as={Link} to='/imprint'>
            Imprint
          </FooterLink>
        </FooterLinks>
        <CopyrightNote>
          © 2020 PATH. All rights reserved.
        </CopyrightNote>
      </FooterContainer>
    </Layout>
  )
}

export default Footer
