import styled from '@emotion/styled'

export default styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${props => props.bgFill || '#fff'};
  width: 70px;
  height: 70px;

  @media screen and (min-width: 52rem)
  {
    width: 90px;
    height: 90px;
  }
`
