import styled from '@emotion/styled'

const HeaderContainer = styled.div`
  padding: 2.875rem 0 0 0;
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  @media screen and (min-width: 39rem)
  {
    flex-flow: row nowrap;
  }
`

const NavContainer = styled.div`
  flex: 1 1 auto;
  padding: 1.5rem 0 0 0;
  order: 3;

  @media screen and (min-width: 39rem)
  {
    order: 2;
    padding: 0 1.5rem;
  }

  @media screen and (min-width: 52rem)
  {
    padding: 0 2rem;
  }
`

const ButtonContainer = styled.div`
  flex: 0 0 auto;
  order: 2;
  margin: 0 0 0 auto;

  @media screen and (min-width: 39rem)
  {
    order: 3;
    margin: 0;
  }
`

export {
  HeaderContainer,
  NavContainer,
  ButtonContainer
}
