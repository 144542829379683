import React from 'react'
import PropTypes from 'prop-types'
import { useLocalRemarkForm } from 'gatsby-tinacms-remark'
import { useStaticQuery, graphql, Link } from 'gatsby'
import LogoCircle from '../LogoCircle'
import Button from '../Button'
import SimpleLink from '../SimpleLink'
import HeaderForm from './form'
import renderAst from '../../utils/renderAst'
import scroller from '../../utils/scroller'

import {
  HeaderContainer,
  NavContainer,
  ButtonContainer
} from './styles'

const Header = ({ withContact, bgFill, logoFill }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      markdownRemark(frontmatter: {blockId: {eq: "header"}}) {
        ...TinaRemark
        frontmatter {
          buttonLabel
        }
        htmlAst
      }
    }
  `)
  const [markdownRemark] = useLocalRemarkForm(data.markdownRemark, HeaderForm)
  return (
    <HeaderContainer>
      <SimpleLink as={Link} to='/'>
        <LogoCircle bgFill={bgFill} logoFill={logoFill} />
      </SimpleLink>
      <NavContainer>
        {renderAst(markdownRemark.htmlAst)}
      </NavContainer>
      {withContact &&
        <ButtonContainer>
          <Button onClick={() => { scroller('contact', 50) }}>
            {markdownRemark.frontmatter.buttonLabel}
          </Button>
        </ButtonContainer>}
    </HeaderContainer>
  )
}

Header.propTypes = {
  withContact: PropTypes.bool,
  bgFill: PropTypes.string,
  logoFill: PropTypes.string
}

export default Header
