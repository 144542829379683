import styled from '@emotion/styled'

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  padding: 2rem 0 2.5rem 0;
`

const FooterLinks = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 0 2rem 0;

  @media screen and (min-width: 30rem)
  {
    flex-flow: row nowrap;
    padding: 0;
  }
`

const FooterLink = styled.a`
  color: #000;
  font-size: 0.875rem;
  text-decoration: underline;
  padding: 1rem 0;
  margin: 0 0.5rem;
  cursor: pointer;

  :hover
  {
    text-decoration: none;
  }
`

const CopyrightNote = styled.span`
  color: #5f6382;
  font-size: 0.875rem;
`

export {
  FooterContainer,
  FooterLinks,
  FooterLink,
  CopyrightNote
}
