import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ title, description, image, pathname }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            siteUrl
          }
        }
      }
    `
  )

  const defaultDescription = description || site.siteMetadata.description
  const defaultTitle = title || site.siteMetadata.title
  const defaultImage = image || site.siteMetadata.image
  const defaultURL = pathname || site.siteMetadata.siteUrl

  return (
    <Helmet defer={false} htmlAttributes={{ lang: 'en' }}>
      <title>{defaultTitle}</title>
      <meta name='robots' content='index, follow' />
      <meta name='description' content={defaultDescription} />
      <meta name='image' content={defaultImage} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={defaultURL} />
      <meta property='og:title' content={defaultTitle} />
      <meta property='og:description' content={defaultDescription} />
      <meta property='og:image' content={defaultImage} />
    </Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null
}

export default Seo
