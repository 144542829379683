import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({ size, fill }) => {
  return (
    <svg
      fill={fill}
      height={size}
      width={size}
      viewBox='0 0 56 56'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M6.94043 30.2614V0H37.7053C46.768 0 51.3542 6.16153 52.627 11.9079C54.0533 18.3972 51.7053 26.7 44.464 30.0211L44.2665 30.1085L42.6207 26.547L42.8182 26.4596C48.1724 24.0125 49.8621 17.7199 48.7868 12.76C47.5361 7.23215 43.4107 3.93289 37.7053 3.93289H10.8903V30.2614H6.94043Z' />
      <path d='M4 54.5143L22.4201 9.18848H32.7367L51.0909 54.5143L47.4263 55.9782L30.0815 13.1214H25.0752L7.66458 56L4 54.5143Z' />
      <path d='M25.5706 41.2954V31.4195H21.5768V27.4866H33.5141V31.4195H29.5204V41.2954H25.5706Z' />
      <path d='M33.3824 55.2789V48.9863H21.7084V55.2789H17.7586V38.8044H21.7084V45.0534H33.3824V38.8044H37.3323V55.2789H33.3824Z' />
    </svg>
  )
}

Logo.propTypes = {
  size: PropTypes.string,
  fill: PropTypes.string
}

Logo.defaultProps = {
  size: '56px',
  fill: '#0085ff'
}

export default Logo
