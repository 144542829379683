import React from 'react'
import RehypeReact from 'rehype-react'

import MakeStrong from '../components/MakeStrong'
import Paragraph from '../components/Paragraph'
import SimpleLink from '../components/SimpleLink'
import PrimaryTitle from '../components/PrimaryTitle'
import SecondaryTitle from '../components/SecondaryTitle'
import TertiaryTitle from '../components/TertiaryTitle'
import QuaternaryTitle from '../components/QuaternaryTitle'
import {
  FancyListContainer,
  FancyListItem
} from '../components/FancyUnorderedList/styles'

export default new RehypeReact({
  createElement: React.createElement,
  components: {
    a: SimpleLink,
    h1: PrimaryTitle,
    h2: SecondaryTitle,
    h3: TertiaryTitle,
    h4: QuaternaryTitle,
    strong: MakeStrong,
    p: Paragraph,
    ul: FancyListContainer,
    li: FancyListItem
  }
}).Compiler
