import styled from '@emotion/styled'
import { lighten } from 'polished'

export default styled.button`
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1;
  padding: 0.9375rem 1.25rem;
  color:
    ${props => props.primary
    ? '#fff'
    : '#000'};
  border-style: solid;
  border-width: 0.125rem;
  border-color:
    ${props => props.alternate
    ? '#fff500'
    : '#000'};
  background-color:
    ${props => props.primary
    ? '#000'
    : props.alternate
    ? '#fff500'
    : 'transparent'};
  border-radius: 1.5rem;
  outline: none;
  user-select: none;
  cursor: pointer;

  :hover
  {
    color:
      ${props => props.primary
      ? '#fff'
      : lighten(0.15, '#000')};
    border-color:
      ${props => props.alternate
      ? lighten(0.15, '#fff500')
      : lighten(0.15, '#000')};
    background-color:
      ${props => props.primary
      ? lighten(0.15, '#000')
      : props.alternate
      ? lighten(0.15, '#fff500')
      : 'transparent'};
  }
`
