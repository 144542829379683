import MoveTo from './moveToWrapper'

const scroller = (element, offset) => {
  if (typeof window !== 'undefined') {
    const node = document.getElementById(element)
    const moveTo = new MoveTo({
      tolerance: offset || 0
    })
    moveTo.move(node)
  }
}

export default scroller
