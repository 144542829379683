import styled from '@emotion/styled'

export default styled.div`
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1rem;
  overflow: hidden;

  @media screen and (min-width: 45rem)
  {
    padding: 0 1.5rem;
  }

  @media screen and (min-width: 66.25rem)
  {
    padding: 0 2rem;
  }
`
