import styled from '@emotion/styled'

import {
  ListContainer,
  ListItem
} from '../SimpleUnorderedList/styles'

const listTypes = {
  triangle: '<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.23686 14.75L7 6.5L11.7631 14.75H2.23686Z" stroke="#000" stroke-width="1.5"/></svg>'
}

const FancyListContainer = styled(ListContainer)`
  padding: 1rem 0 0 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;

  > li
  {
    margin: 0 0 0.5rem 0;
    width: 100%;
  }
`

const FancyListItem = styled(ListItem)`
  font-weight: 400;
  line-height: 1.25;
  padding: 0 0 0 1.5rem;
  color: #000;
  background-image:
    url(
      "data:image/svg+xml;charset=utf-8,${encodeURIComponent(listTypes.triangle)}"
    );
  background-size: 14px 18px;
  background-repeat: no-repeat;
  background-position: left top;
`

export {
  FancyListContainer,
  FancyListItem
}
