const HeaderForm = {
  label: 'Header',
  fields: [
    {
      label: 'Navigational sentence',
      name: 'rawMarkdownBody',
      description: 'Please keep the contents wrapped in a tag',
      component: 'markdown'
    },
    {
      label: 'Button label',
      name: 'frontmatter.buttonLabel',
      component: 'text'
    }
  ]
}

export default HeaderForm
