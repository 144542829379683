import styled from '@emotion/styled'

export default styled.h4`
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.25;
  color: #000;
  white-space: pre-line;
  margin: 1rem 0 0.5rem 0;
`
