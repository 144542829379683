import styled from '@emotion/styled'

export default styled.h1`
  font-size: 2.25rem;
  font-weight: 900;
  line-height: 1.25;
  color: #fff;
  white-space: pre-line;
  margin: 0;

  @media screen and (min-width: 23rem)
  {
    font-size: 2.5rem;
  }

  @media screen and (min-width: 52rem)
  {
    font-size: 3rem;
  }

  @media screen and (min-width: 69rem)
  {
    font-size: 3.75rem;
  }
`
