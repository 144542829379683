import styled from '@emotion/styled'

export default styled.h3`
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.25;
  color: #000;
  white-space: pre-line;
  margin: 0.5rem 0;

  @media screen and (min-width: 52rem)
  {
    font-size: 2rem;
  }
`
