import React from 'react'
import PropTypes from 'prop-types'

import Logo from '../Logo'
import StyledLogoContainer from './styles'

const LogoCircle = ({ bgFill, logoFill }) => {
  return (
    <StyledLogoContainer
      bgFill={bgFill}
    >
      <Logo
        size='63%'
        fill={logoFill}
      />
    </StyledLogoContainer>
  )
}

LogoCircle.propTypes = {
  bgFill: PropTypes.string,
  logoFill: PropTypes.string
}

LogoCircle.defaultProps = {
  bgFill: '#fff',
  logoFill: '#0085ff'
}

export default LogoCircle
