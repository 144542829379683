import styled from '@emotion/styled'

const ListContainer = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0 0 0 2rem;
`

const ListItem = styled.li`
  font-size: 1.125rem;
  line-height: 1.65;
  margin: 0;
`

export {
  ListContainer,
  ListItem
}
